import { useSpring, animated } from "react-spring";
import { logo_no_bg } from "web/assets";
import * as animations from "../utils/animations";
import { Container } from 'react-bootstrap'

const LoadingScreen = () => {
  const blink = useSpring(animations.blink);
  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center position-fixed w-100 h-100"
      style={{background: "#fff", ...styles.parentContainer}}
    >
      {/* <div
        className="h-100vh d-flex justify-content-center align-items-center"
      > */}
      <animated.img src={logo_no_bg} alt="" height={30 + "%"} style={blink} />
      {/* </div> */}
    </Container>
  );
};

const styles = {
  parentContainer: {
    zIndex: 9999,
    top: 0,
    bottom: 0
  }
}

export default LoadingScreen;
