import { useState } from "react";
import { useRef } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import saveContact from "web/api/contactusForm";
import { toast, Slide } from "react-toastify";

const ContactUsForm = ({
  inputBgClassName = "home-background",
  labelFcClassName = "fc-dfd3be",
}) => {
  const formRef = useRef(null);
  const [formBeingSubmitted, setFormBeingSubmitted] = useState(false);

  const notify = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Slide,
      });
    }
  };

  const submitHandler = async (e) => {
    setFormBeingSubmitted(true);
    const response = await saveContact(e, new FormData(formRef.current));
    if (response) {
      notify("Thank you for contacting us! We'll get back to you soon.", "success");
      setFormBeingSubmitted(false);
      formRef.current.reset();
    } else {
      notify("Contact has not been submitted.", "error");
      setFormBeingSubmitted(false);
    }
  };

  return (
    <Form onSubmit={(e) => submitHandler(e)} ref={formRef}>
      <Form.Group className="mb-4" controlId="formBasicEmail">
        <Form.Label className={"f-footer-body-2 " + labelFcClassName}>
          Name
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          placeholder="Your name"
          required
          className={"p-3 rounded-0 text-white " + inputBgClassName}
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="formBasicPassword">
        <Form.Label className={"f-footer-body-2 " + labelFcClassName}>
          Work Email
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          required
          className={"p-3 rounded-0 text-white " + inputBgClassName}
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="formBasicPhone">
        <Form.Label className={"f-footer-body-2 " + labelFcClassName}>
          Phone no
        </Form.Label>
        <Form.Control
          type="phone"
          name="phone"
          pattern="[0-9]{10}"
          placeholder="Phone number"
          required
          onInvalid={(e) => {
            return e.target.setCustomValidity(
              "Please enter valid mobile number."
            );
          }}
          onChange={(e) => {
            return e.target.setCustomValidity("");
          }}

          className={"p-3 rounded-0 text-white " + inputBgClassName}
        />
      </Form.Group>

      <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
        <Form.Label className={"f-footer-body-2 " + labelFcClassName}>
          Message
        </Form.Label>
        <Form.Control
          as="textarea"
          name="message"
          rows={5}
          placeholder="Type Your Message Here"
          required
          className={"p-3 rounded-0 text-white " + inputBgClassName}
        />
      </Form.Group>
      {!formBeingSubmitted ? (
        <Button className="send-message-button" type="submit">
          Submit
        </Button>
      ) : (
        <Button
          className="send-message-button d-flex flex-row align-items-center justify-content-center"
          type="submit"
          disabled
        >
          <Spinner animation="border" size="sm" role="status" />{" "}
          <span className="ps-4">Submiting... </span>
        </Button>
      )}
    </Form>
  );
};

export default ContactUsForm;
