import React, { useEffect } from "react";
import NavBar from "web/components/NavBar";
import Footer from "web/components/Footer";
import { useState } from "react";
import { useSpring, animated } from "react-spring";
import * as animations from "../utils/animations";
import {
  placeholder_client,
  avinashAgrawal,
  saurabhBorkar,
  gauravParmar,
  omkarPingale,
  rupaliDeshmukh,
  mominZeeshan,
  hambirNalawade,
  sureshTalwar,
  ajitSuryavanshi,
  akshayBhokare,
  team_bg,
} from "../assets";
import { Image } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Team = () => {
  const fade = useSpring(animations.fadeOut);
  const slideFromRight = useSpring(animations.slideFromRight);
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);
  const [showName, setShowName] = useState(false);
  const [memberName, setMemberName] = useState(null);
  const location = useLocation();

  const teamMembers = [
    [
      { key: 1, name: "Avinash Agrawal", image: avinashAgrawal },
      { key: 2, name: "Saurabh Borkar", image: saurabhBorkar },
      { key: 3, name: "Gaurav Parmar", image: gauravParmar },
    ],
    [
      { key: 4, name: "Omkar Pingale", image: omkarPingale },
      { key: 5, name: "Rupali Deshmukh", image: rupaliDeshmukh },
      { key: 6, name: "Momin Zeeshan", image: mominZeeshan },
      { key: 7, name: "Hambir Nalawade", image: hambirNalawade },
    ],
    [
      { key: 8, name: "Suresh Talwar", image: sureshTalwar },
      { key: 9, name: "Ajit Suryavanshi", image: ajitSuryavanshi },
      { key: 10, name: "Akshay Bhokare", image: akshayBhokare },
    ],
  ];

  return (
    <>
      <Helmet>
        <title>{`StructSource | Team`}</title>
        <meta name="description" content="Team" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar />
      <Image className="position-absolute w-100" style={{filter: "blur(4px)"}} src={team_bg}/>
      <animated.div
        className="mt-5 pt-5 d-flex flex-column justify-content-center align-items-center"
        style={slideFromRight}
      >
        <h1 className="p-0 f-project-title" style={{zIndex: 20}}>Meet our Team</h1>
      </animated.div>
      <animated.div
        className="first-section d-flex flex-column justify-content-center"
        style={slideFromRight}
      >
        {
          teamMembers.map((teamMember, index) => {
            return (
              <div className="d-flex flex-row justify-content-center align-items-center">
                {
                  teamMember.map((member) => {
                    return (
                      <div
                        className="hexagon-image-team"
                        onMouseEnter={() => {
                          setShowName(true)
                          setMemberName(member.name)
                        }}
                        onMouseLeave={() => {
                          setShowName(false)
                          setMemberName(null)
                        }}
                      >
                        <img src={member.image} alt="" className="w-100" />
                        {showName && memberName === member.name && (
                          <animated.div style={fade} className="hexagon-image-team-info text-center">
                            <p className="text-white f-contact-p">{member.name}</p>
                          </animated.div>
                        )}
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
        <div>
          {/* <div>
            <div
              className="hexagon-image-team"
              onMouseEnter={() => setShowName(true)}
              onMouseLeave={() => setShowName(false)}
            >
              <img src={placeholder_client} alt="" className="w-100" />
              {showName && (
                <animated.div style={fade} className="hexagon-image-team-info">
                  <p className="text-white f-contact-p">Akshay Lilani</p>
                </animated.div>
              )}
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
          </div> 
          <div className="d-flex flex-row justify-content-center">
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
          </div>
          <div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
            <div className="hexagon-image-team">
              <img src={placeholder_client} alt="" className="w-100" />
            </div>
          </div> */}
        </div>
      </animated.div>
      {/* <div className="mt-5"></div> */}
      <Footer />
    </>
  );
};

export default Team;
