import React from "react";

const Map = () => (
    <iframe
    title="map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.3373604357585!2d73.8263922148927!3d18.51365088741305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf9ea68ecd21%3A0xd0233edfefbf287f!2sStruct%20Source%20Engineering%20(SSE)!5e0!3m2!1sen!2sin!4v1672722503876!5m2!1sen!2sin"
      width="100%"
      height="100%"
      style={{border:0}}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
);

export default Map;
