import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { logo, logo_no_bg } from "../assets";
import { Link, useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";

const NavBar = () => {
  const slideFromRight = useSpring({
    from: { width: "0" },
    to: { width: "50%" },
    reset: false
  })
  const location = useLocation();
  return (
    <Navbar
      bg="light"
      collapseOnSelect
      expand="md"
      className="navbar-custom mx-2 mx-sm-4 mb-2 px-4 py-2 d-flex justify-content-between align-items-center user-select-none"
    >
      <Navbar.Brand as={Link} to="/" className="d-flex flex-row align-items-center cursor-pointer">
        <img alt="" src={logo_no_bg} style={{ backgroundColor: "#fff", maxWidth: 50 }} />
        <span className="f-nav-title ps-sm-1 fw-bold text-primary">
          Structsource <br />
          Engineering Solutions
        </span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className="justify-content-end">
        <Nav className="m-auto">
          <div>
            <Nav.Link as={Link} to="/" className="f-nav-link">
              Home{" "}
            </Nav.Link>
            {location.pathname === "/" && <animated.p style={(location.pathname === "/") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
          <div>
            <Nav.Link as={Link} to="/aboutus" className="f-nav-link">
              About Us
            </Nav.Link>
            {location.pathname === "/aboutus" && <animated.p style={(location.pathname === "/aboutus") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
          <div>
            <Nav.Link as={Link} to="/projects" className="f-nav-link">
              Projects
            </Nav.Link>
            {(location.pathname === "/projects" || location.pathname.includes("/projects/")) && <animated.p style={(location.pathname === "/projects") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
          <div>
            <Nav.Link as={Link} to="/team" className="f-nav-link">
              Team
            </Nav.Link>
            {location.pathname === "/team" && <animated.p style={(location.pathname === "/team") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
          <div>
            <Nav.Link as={Link} to="/career" className="f-nav-link">
              Careers
            </Nav.Link>
            {location.pathname === "/career" && <animated.p style={(location.pathname === "/career") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
          <div>
            <Nav.Link as={Link} to="/blog" className="f-nav-link">
              Blogs
            </Nav.Link>
            {location.pathname === "/blog" && <animated.p style={(location.pathname === "/blog") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
          </div>
        </Nav>
        <Nav className="ms-auto flex-column">
          <Nav.Link
            as={Link}
            to="/contactus"
            className="f-nav-link"
            href="#home"
          >
            Contact Us
          </Nav.Link>
          {location.pathname === "/contactus" && <animated.p style={(location.pathname === "/contactus") ? slideFromRight : {}} className="border-bottom-nav"></animated.p>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBar;
