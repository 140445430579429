import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import Home from "./web/pages/Home";
import AboutUs from "./web/pages/AboutUs";
import Projects from "./web/pages/Projects";
import ProjectsList from "./web/pages/ProjectsList";
import ProjectInfo from "./web/pages/ProjectInfo";
import Contact from "./web/pages/Contact";
import Team from "./web/pages/Team";
import Career from "./web/pages/Career";
import Blog from "./web/pages/Blog";
import BlogInfo from "./web/pages/BlogInfo";
import { useReadyStateEffect } from "react-ready-state-effect";
import LoadingScreen from "./web/components/LoadingScreen";
import ScrollToTop from "./web/components/ScrollToTop";
import PrivacyPolicy from "web/pages/PrivacyPolicy";


const Routers = () => {
  const [isDocumentLoading, setDocumentLoadingState] = useState(true);

  useReadyStateEffect(
    () => {
      console.log(`Document loading completed.`);
      setTimeout(() => setDocumentLoadingState(false), 1500);
    },
    [],
    "complete"
  );

  return (
    <div className="user-select-none">
      <Router>
        <ScrollToTop />
        {isDocumentLoading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/projects" element={<Projects />}>
              <Route path="" element={<ProjectsList />} />
              <Route path=":slug" element={<ProjectInfo />} />
            </Route>
            <Route path="/team" element={<Team />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blog" element={<Blog />}>
              <Route path=":slug" element={<BlogInfo />} />
            </Route>
            <Route path="/contactus" element={<Contact />} />
            <Route path="/loading" element={<LoadingScreen />} />
          </Routes>
        )}
      </Router>
    </div>
  );
};

export default Routers;
