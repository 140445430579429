import slugify from "slugify";
import {
  project_detail_placeholder,
  buildingOne,
  buildingSeven,
  buildingEight,
  infraOne,
  infraTwo,
  infraThree,
  infraFour,
  infraFive,
  infraSeven,
  infraEight,
  infraNine,
  infraTen,
  infraEleven,
  infraTwelve,
  infraThirteen,
  infraFourteen,
  infraFifteen,
  infraSeventeen,
  infraEighteen,
  infraNineteen,
  infraTwenty,
  infraTwentyOne,
  // infraTwentyTwo,
  // infraTwentyThree,
  // infraTwentyFour,
  infraTwentyFive,
  infraTwentySix,
  infraTwentySeven,
  // infraTwentyEight,
  // infraTwentyNine,
  // infraThirty,
  // infraThirtyOne,
  infraThirtyTwo,
  infraThirtyThree,
  // infraThirtyFour,
  infraThirtyFive,
  infraThirtySix,
  infraThirtySeven,
  infraThirtyEight,
} from "../assets";

const status = { c: "Completed", ip: "In Progress", n: "-" }

const buildings = [
  {
    id: 1,
    name: "Structural Design of Various Buildings including Yacht Clubs, Restaurants, and Village Villas at Sindalaha Island, KSA",
    short_name: "Design of Yacht Clubs, Restaurants, and Village Villas",
    client: "Ramboll Dubai",
    cost: "-",
    status: status.ip,
    image: buildingOne,
  },
  {
    id: 2,
    name: "Design of Utility Structures for Neom City (The Line Project). KSA",
    short_name: "Design of Utility Structures",
    client: "VKU-URBAN",
    cost: "-",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 3,
    name: "Design of Miwan Building G+12 and G+11 for MHDC at Titwala, Thane.",
    short_name: "Design of Miwan Building G+12 and G+11",
    client: "Ingrain Architects",
    cost: "50 crores",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 4,
    name: "Design of Low-cost Housing for MHDC at BARAMATI, Dist.-PUNE",
    short_name: "Design of Low-Cost Housing",
    client: "Ingrain Architects",
    cost: "70 crores",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 5,
    name: "Design of Steel Superstructure for AVENUES, Khobar, Dubai",
    short_name: "Design of Steel Superstructure for AVENUES",
    client: "Ramboll Dubai",
    cost: "-",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 6,
    name: "Structural Review of Various projects for Mckinsey as a Team part of VKU-Urban",
    short_name: "Structural Review",
    client: "Mckinsey",
    cost: "-",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 7,
    name: "Misk Arts Institute, Studio and Gallery Building, Riyadh, KSA",
    short_name: "Design of Misk Arts Institute",
    client: "Ramboll Dubai ",
    cost: "-",
    status: status.ip,
    image: buildingSeven,
  },
  {
    id: 8,
    name: "WADI HANIFAH-Cultural ASSETS, KSA",
    short_name: "WADI HANIFAH-Cultural ASSETS",
    client: "Ramboll Dubai",
    cost: "-",
    status: status.ip,
    image: buildingEight,
  },
];

const infrastructures = [
  {
    id: 1,
    name: "Design and Construction of Industrial Shade at Kurkumbh MIDC, Tal- Daund, Dist-Pune",
    short_name: "Design and Construction of Industrial Shade",
    client: "SSPL India Pvt.Ltd",
    cost: "50 lacs",
    status: status.c,
    image: infraOne,
  },
  {
    id: 2,
    name: "Design and Proof checking of 100 m long bridge work on Creek in DP road from Agasan to Kalyan Rd",
    short_name: "Design and Proof checking of 100 m long bridge work on Creek",
    client: "Thane Municipal Corporation",
    cost: "6 crores",
    status: status.c,
    image: infraTwo,
  },
  {
    id: 3,
    name: "Proof checking of structural design Of Bridge on Kham river",
    short_name: "Proof checking of structural design Of Bridge on Kham river",
    client: "CIDCO, Aurangabad",
    cost: "2 crores",
    status: status.c,
    image: infraThree,
  },
  {
    id: 4,
    name: "Structural Design for MSETCL EHVT Line re-routing 260 M long Bridge housing provision of EHVT line Duct and 800 Dia Water pipe line",
    short_name: "Structural Design for MSETCL EHVT Line",
    client: "CIDCO, Aurangabad",
    cost: "16 crores",
    status: status.c,
    image: infraFour,
  },
  {
    id: 5,
    name: "Design and Construction of Bridge at Hiranandani Fortune City Panvel.",
    short_name: "Design and Construction of Bridge at Hiranandani Fortune City Panvel.",
    client: "HiranandaniGroup/AB Infra, Pali",
    cost: "6.5 crores",
    status: status.c,
    image: infraFive,
  },
  {
    id: 6,
    name: "Value Engineering Services for Piped Water Supply scheme for Sector-1 at Khandala",
    short_name: "Value Engineering Services for Piped Water Supply",
    client: "HiranandaniGroup/AB Infra, Pali",
    cost: "1.4 crores",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 7,
    name: "Professional Service for Land pattern study, preliminary survey, Boundary marking, utility survey design, and Budget Preparation for Khandala Site",
    short_name: "Professional Service for Khandala Site",
    client: "HiranandaniGroup/AB Infra, Pali",
    cost: "20 crores",
    status: status.c,
    image: infraSeven,
  },
  {
    id: 8,
    name: "Consultancy for Design of bridge on Nala in prabhag no 20 and 26 at Mohammadwadi, Pune",
    short_name: "Consultancy for Design of bridge on Nala",
    client: "AR Constructions",
    cost: "5.5 crore",
    status: status.c,
    image: infraEight,
  },
  {
    id: 9,
    name: "Structural Design Consultancy of Submersible Bridge at SaiwanAmbodeTansaRiver VR 26, TalVasai, Dist-Palghar",
    short_name: "Structural Design Consultancy of Submersible Bridge",
    client: "Shivsai Constructions/PWD Palghar Division",
    cost: "2.5 crore",
    status: status.ip,
    image: infraNine,
  },
  {
    id: 10,
    name: "Structural Design Consultancy of ROB LC 47A at KM 93/400 on Virar Surat Rail Line on ZaiBordi Revas Reddy Satterday MSh-4 ch 58/500, Dist-Palghar",
    short_name: "Structural Design Consultancy of ROB LC 47A at KM 93/400",
    client: "Shivsai Constructions/PWD Palghar Division",
    cost: "13 crores",
    status: status.c,
    image: infraTen,
  },
  {
    id: 11,
    name: "Design and Consultancy of Minor Bridge at Mantri market Hadapsar",
    short_name: "Design and Consultancy of Minor Bridge",
    client: "Pune Municipal corporation",
    cost: "0.6 Crores",
    status: status.c,
    image: infraEleven,
  },
  {
    id: 12,
    name: "Structural Design Consultancy of ROB LC 46A at KM 90/500 on Western Rail Line on Palghar NavleeVerur Road, ODR No 57 (KM 0/400) Dist-Palghar",
    short_name: "Structural Design Consultancy of ROB LC 46A at KM 90/500",
    client: "Shivsai Constructions/PWD Palghar Division",
    cost: "20 crores",
    status: status.ip,
    image: infraTwelve,
  },
  {
    id: 13,
    name: "RCC Design Consultancy for various structures on DP road to CharoliLohegoan Border Part-1 CH 3/650 to 6/700 and part-2 CH 6/700 to 9/560",
    short_name: "RCC Design Consultancy for various structures",
    client: "Pavetech Consultants, Pune",
    cost: "100 crores",
    status: status.c,
    image: infraThirteen,
  },
  {
    id: 14,
    name: "Structural Design of all Box Culverts and Minor Bridges for HYBRIDannuity mode,package no. pn-150:- improvement to math kudal-shivdav-kadgaon-gargoti road,sh-179,km 55/000 to 94/000, tal.bhudargad, in kolhapur Maharashtra",
    short_name: "Structural Design of all Box Culverts and Minor Bridges for HYBRIDannuity mode",
    client: "Pavetech Consultants, Pune",
    cost: "600 crores",
    status: status.c,
    image: infraFourteen,
  },
  {
    id: 15,
    name: "Design of Box culverts at 18 m wide DP road from Burdewasti main road to survey number 87, Prabag 7, Charoli ",
    short_name: "Design of Box culverts at 18 m wide DP road",
    client: "Pavetech Consultants, Pune",
    cost: "1 crore",
    status: status.c,
    image: infraFifteen,
  },
  {
    id: 16,
    name: "BOQ verification for HCMTR Corridor integration with metro rail alignment",
    short_name: "BOQ verification for HCMTR Corridor integration with metro rail alignment",
    client: "Pavetech Consultants, Pune",
    cost: "5500 crore",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 17,
    name: "Development of 18 m wide road for PCMC from Akurdi Railway station to gurudwara chowk.",
    short_name: "Development of 18 m wide road for PCMC",
    client: "Pavetech Consultants, Pune",
    cost: "2.5 crore",
    status: status.c,
    image: infraSeventeen,
  },
  {
    id: 18,
    name: "Structural Audit of bridges for PWD for the work of Improvements to Top Wadgaon Shirgaon Ashta Tasgaon Atpadi Road, SH-151, KM 0/000 to 127 MAHARASHTRA. Package 43 and Package 45",
    short_name: "Structural Audit of bridges for PWD",
    client: "Avantika Constructions",
    cost: "400 crores",
    status: status.c,
    image: infraEighteen,
  },
  {
    id: 19,
    name: "Structural Audit of bridges for PWD for the work of Improvements to Kolhapur Gargoti Gadhinglaj Naganwadi Road, SH-189, KM, 5/400 to 163/000 in Kolhapur district. (Part-Kolhapur to Gargoti Road Km 5/500 to Km 42/250 and Gadhinglaj to Tillari Road Km 113/00 to Km 156/000 excluding the overlap length of NH-2.1Km) Package No –PN47 Package No –PN48",
    short_name: "Structural Audit of bridges for PWD",
    client: "Jitendra Singh Infrastructure",
    cost: "600 crores",
    status: status.c,
    image: infraNineteen,
  },
  {
    id: 20,
    name: "Structural Design of Minor Bridge Structure 32 x 12 m on nallah VN1 (Pandurang Industrial Estate) on 12 m wide service road next to NH-4 from Prayeja City to Warje",
    short_name: "Structural Design of Minor Bridge",
    client: "Pavetech Consultants, Pune",
    cost: "75 lacs",
    status: status.c,
    image: infraTwenty,
  },
  {
    id: 21,
    name: "Development of 30 m wide DP road at Pune Solapur main road Hadapsar Survey number 85 to 79 to 80 upto main railway line including all allied works",
    short_name: "Development of 30 m wide DP road",
    client: "Pavetech Consultants, Pune",
    cost: "10 crores",
    status: status.ip,
    image: infraTwentyOne,
  },
  {
    id: 22,
    name: "Proof checking for submersible bridge at Warna River at Shirala Fakirwadi Mangale MDR-7 connecting to Kolhapur and Satara Dist",
    short_name: "Proof checking for submersible bridge",
    client: "PWD Design Circle Pune",
    cost: "15 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 23,
    name: "Preparation of Priced BOQ for 'Western Gateway Project Early Production Facilities at JW Port, Ratnagiri'",
    short_name: "Preparation of Priced BOQ",
    client: "H- Energy/AB Infra, Pali",
    cost: "30 Crores",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 24,
    name: "Preparation of Priced BOQ for 'Western Gateway Project Early Production Facilities at Dabhol.'",
    short_name: "Preparation of Priced BOQ",
    client: "H Energy/AB Infra, Pali",
    cost: "8 crores",
    status: status.c,
    image: project_detail_placeholder,
  },
  {
    id: 25,
    name: "Structural Design of 30 m Wide Skew Bridge located on Alandi Dehu DP Road",
    short_name: "Structural Design of 30 m Wide Skew Bridge",
    client: "Pavetech Consultants, Pune",
    cost: "8 Crores",
    status: status.ip,
    image: infraTwentyFive,
  },
  {
    id: 26,
    name: "Detailed Structural Audit of Vashishti River Bridge and Shashtri River Bridge on NH-66 (Mumbai- Goa Highway) Scope-Visual Inspection, NDT and Under Water Inspection",
    short_name: "Detailed Structural Audit of Vashishti and Shashtri River Bridge",
    client: "PWD, NHAI Ratnagiri Division, Maharashtra",
    cost: "-",
    status: status.c,
    image: infraTwentySix,
  },
  {
    id: 27,
    name: "Structural Design Consultancy of Submersible Bridge on Warana River, Shirala Mangale Road MDR-7, DistSangali",
    short_name: "Structural Design Consultancy of Submersible Bridge",
    client: "Balaji Constructions/PWD Sangali Division",
    cost: "10 crores",
    status: status.c,
    image: infraTwentySeven,
  },
  {
    id: 28,
    name: "Project Management Consultancy of Submersible Bridge on Warana River, Shirala Mangale Road MDR-7, DistSangali",
    short_name: "Project Management Consultancy of Submersible Bridge",
    client: "Balaji Constructions/PWD Sangali Division",
    cost: "10 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 29,
    name: "Project Management Consultancy for Construction of ROB at Palghar on DFC Ch 124+632.226",
    short_name: "Project Management Consultancy for Construction of ROB",
    client: "Shivsai Constructions/IRCON",
    cost: "20 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 30,
    name: "Project Management Consultancy for Construction of ROB at Saphaleon DFC Ch 110+916",
    short_name: "Project Management Consultancy for Construction of ROB",
    client: "Shivsai Constructions/IRCON",
    cost: "20 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 31,
    name: "Structural Design of 30 m Wide 30 m Long Bridge located Near Lohia Garden, Hadpsar, Pune",
    short_name: "Structural Design of 30 m Wide 30 m Long Bridge",
    client: "Pavetech Consultants, Pune",
    cost: "7 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 32,
    name: "Structural Design and Project Management Consultancy of High Level Bridge at Kanhe Phata, Talegoan.",
    short_name: "Structural Design and Project Management Consultancy of High Level Bridge",
    client: "AR Construction",
    cost: "7.5 cr",
    status: status.ip,
    image: infraThirtyTwo,
  },
  {
    id: 33,
    name: "Structural design of major bridge at village Birwadi on Birwadi walar Sandoshi road sh 102.Taluka Mahad. District-Raigad",
    short_name: "Structural design of major bridge at village Birwadi",
    client: "ZAD Enterprises",
    cost: "6.5 cr",
    status: status.ip,
    image: infraThirtyThree,
  },
  {
    id: 34,
    name: "Providing Structural Design and PMC for the Construction of the Retaining Wall around the UG Water tank (Saman Panipurvatha Scheme)",
    short_name: "Providing Structural Design and PMC for Retaining Wall around the UG Water tank",
    client: "PMC",
    cost: "4.8 crores",
    status: status.ip,
    image: project_detail_placeholder,
  },
  {
    id: 35,
    name: "Greater Male’ Connectivity – Male’ to Thilafushi Link Project, Republic of Maldives. Temporary structure 3rd Party Review.",
    short_name: "Greater Male’ Connectivity – Male’ to Thilafushi Link Project",
    client: "AFCONS",
    cost: "Total project 530 million USD",
    status: status.ip,
    image: infraThirtyFive,
  },
  {
    id: 36,
    name: "Bridge Load Test Scheme. Repair and Rehabilitation of Balganga River bridge at CH. 20+144 on PanvelIndapur NH66 Road Test Scheme",
    short_name: "Repair and Rehabilitation of Balganga River bridge",
    client: "NATIONAL HIGHWAY AUTHORITY OF INDIA.",
    cost: "25 lacs",
    status: status.ip,
    image: infraThirtySix,
  },
  {
    id: 37,
    name: "SHUTTERING DESIGN FOR Implementation of improvement of Chhatrapati Shivaji Maharaj international airport domestic terminal(t1) junction by the construction of vehicular underpass & widening of a flyover between km 5+300 to 6+100 of western express highway in Mumbai",
    short_name: "Improvement of Chhatrapati Shivaji Maharaj international airport domestic terminal(T1)",
    client: "Design and Detailing of Shuttering with its 3D model and connection design.",
    cost: "",
    status: status.n,
    image: infraThirtySeven,
  },
  {
    id: 38,
    name: "Baramati 5 Canal Bridge",
    short_name: "Baramati 5 Canal Bridge",
    client: "Baramati Canal Bridge",
    cost: "-",
    status: status.n,
    image: infraThirtyEight,
  },
];

buildings.map((building) => {
  building.slug = slugify(building.name);
  building.type = "Buildings"
  return building
})
infrastructures.map((infra) => {
  infra.slug = slugify(infra.name);
  infra.type = "Infrastructures"
  return infra
})
export { buildings, infrastructures };
