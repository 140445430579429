import React from "react";

const slideFromRight = {
  from: { x: -2000 },
  to: { x: 0 },
  reset: false,
  delay: 900,
};

const slideToRight = {
  from: { x: 0 },
  to: { x: 10 },
  config: { duration: 500 },
  reset: true,
};

const fadeIn = {
  from: { opacity: 0 },
  to: { opacity: 1 },
  config: { duration: 500 },
  delay: 900,
};

const slideToLeft = {
  from: { x: 10 },
  to: { x: 0 },
  config: { duration: 200 },
  reset: true,
};

const blink = {
  from: { opacity: 0 },
  to: { opacity: 1 },
  reset: true,
  loop: true,
  config: { duration: 1000 },
  onRest: { reverse: true },
};

const fadeOut = {
  to: { opacity: 1 },
  from: { opacity: 0 },
  reset: true,
};

export { slideFromRight, slideToRight, slideToLeft, fadeIn, blink, fadeOut };
