import React, { useEffect } from "react";
import NavBar from "web/components/NavBar";
import Footer from "web/components/Footer";
import { Row, Col, Form, Button, Image, Container } from "react-bootstrap";
import { aboutus_man, aboutus_mission, aboutus_certificate, concept_design, final_design, design_development, construction_admin } from "web/assets";
import { useSpring, animated } from "react-spring";
import * as animation from "../utils/animations";
import { useRef } from "react";
import ContactUsForm from "web/components/ContactUsForm";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


const AboutUs = () => {
  const slideFromRight = useSpring(animation.slideFromRight);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  return (
    <div className="aboutus-page user-select-none">
      <Helmet>
        <title>{`StructSource | About Us`}</title>
        <meta name="description" content="About Us" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar />
      <animated.div style={slideFromRight}>
        <div className="h-90vh aboutus-background first-section d-flex flex-column justify-content-between align-items-center">
          <Row
            className="w-100 h-100 px-3 justify-content-around align-items-center"
            style={{ maxWidth: 1366 + "px" }}
          >
            <Col className="col-12 col-md-6 p-0 ps-lg-5 d-flex flex-column justify-content-center">
              <h1 className="f-home-title">About Us</h1>
              <p className="f-home-p">
                Struct-Source Engineering Solutions is an engineering firm
                established by a group of expert Engineers with wide experience.
                We have experienced professionals with stellar profession and
                having years of experience in design consultancy and management
                of various buildings as well as infrastructure projects. Our
                team consists of engineering masters of various fields who have
                taken rich experience in multi-nationals and now are a part of
                Struct-Source. We strive for excellence and provide client with
                best Engineering Solution within the cost frame. We have group
                of Structural Engineers, Landscape Engineers, Geo-tech Engineers
                and Project managers. We provide all services right from
                planning, designing, estimation to final project management.
              </p>
            </Col>
            <Col className="about-us-image col-12 col-md-6 p-0 d-flex justify-content-center">
              <img src={aboutus_man} alt="" className="py-3" />
            </Col>
          </Row>
        </div>
        <div className="min-h-90vh bg-white d-flex flex-column justify-content-between position-relative">
          <div className="d-flex flex-column justify-content-between align-items-center">
            <h1 className="text-center f-home-title fc-dfd3be pt-5 text-primary">Scope of Work in structural design</h1>
            <Container className="my-5 py-5">
              <Row className="my-5">
                <Col lg={3} xs={6} className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center justify-content-center border" style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: "#DFD3BE" }}>
                    <Image style={{ maxWidth: 50, maxHeight: 50 }} src={concept_design} />
                  </div>
                  <h6 className="my-4 text-primary text-center">CONCEPT DESIGN</h6>
                </Col>
                <Col lg={3} xs={6} className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center justify-content-center border" style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: "#DFD3BE" }}>
                    <Image style={{ maxWidth: 50, maxHeight: 50 }} src={design_development} />
                  </div>
                  <h6 className="my-4 text-primary text-center">DESIGN DEVELOPMENT</h6>
                </Col>
                <Col lg={3} xs={6} className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center justify-content-center border" style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: "#DFD3BE" }}>
                    <Image style={{ maxWidth: 50, maxHeight: 50 }} src={final_design} />
                  </div>
                  <h6 className="my-4 text-primary text-center">FINAL DESIGN</h6>
                </Col>
                <Col lg={3} xs={6} className="d-flex flex-column justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center justify-content-center border" style={{ width: 100, height: 100, borderRadius: 50, backgroundColor: "#DFD3BE" }}>
                    <Image style={{ maxWidth: 50, maxHeight: 50 }} src={construction_admin} />
                  </div>
                  <h6 className="my-4 text-primary text-center">CONSTRUCTION ADMINISTRATION</h6>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-flex justify-content-center pt-8 ">
            <img src={aboutus_mission} alt="" className="mission-image" />
          </div>
          <div className="position-absolute mission-text">
            <h1 className="f-background-title">Principles</h1>
          </div>
        </div>
        <div className="certificate-section d-flex flex-column justify-content-around">
          {/* <div className="d-flex flex-column align-items-center justify-content-around">
            <h1 className="text-center f-home-title mt-3">Certificate</h1>
            <p className="width-custom px-3 text-center f-home-p">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              Laudantium quam perferendis doloribus ad reiciendis officiis
              corrupti tempora earum, neque, exercitationem quos sed. Illo ad
              dolores molestiae, non ea esse minus? Hic at cupiditate fuga
              sapiente? Quos omnis repellendus possimus facere error. Excepturi,
              nulla. Perspiciatis, veritatis deleniti! Pariatur ducimus fuga,
              cumque nostrum accusamus ratione voluptas. Aspernatur, quis
              recusandae! Quidem, iste quaerat.
            </p>
          </div>
          <div className="d-flex justify-content-center my-3">
            <img
              src={aboutus_certificate}
              alt=""
              className="aboutus-certificate"
            />
          </div> */}
        </div>
        <div className="position-relative home-background aboutus-contact d-flex flex-row justify-content-center align-items-center">
          <div
            className="contact-us w-100 m-0 d-flex flex-md-row flex-column justify-content-center "
            style={{ zIndex: 2 }}
          >
            <div className="get-in-touch-container get-in-touch-container d-flex flex-md-column flex-row justify-content-center">
              <h1 className="w-100 f-get-in-touch text-md-start text-end ">
                Get In
              </h1>
              <h1 className="w-100 f-get-in-touch text-md-end text-start ms-3 ms-md-0">
                Touch
              </h1>
            </div>
            <div className="home-contact-form px-4 px-lg-0">
              <ContactUsForm />
            </div>
          </div>
          <Row className="position-absolute bottom-0 overflow-hidden d-flex justify-content-end align-items-center">
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-1">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div "></div>
              <div className="hex-bg-div opacity-0"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div opacity-0"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-2">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-3">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
          </Row>
        </div>
        <Footer />
      </animated.div>
    </div>
  );
};

export default AboutUs;
