import React from "react";
import Footer from "web/components/Footer";
import { Container } from "react-bootstrap";
import NavBar from "web/components/NavBar";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


const PrivacyPolicy = () => {
  const location = useLocation();
  return (
    <>
    <Helmet>
        <title>{`StructSource | Privacy Policy`}</title>
        <meta name="description" content="Privacy Policy" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar/>
      <Container className="my-5 user-select-none">
        <h1 className="my-5">Privacy Policy</h1>
        <div>
          <p>
            Your privacy is critical to us. Likewise, we have built up this Policy with the end goal you should see how we gather, utilize, impart and reveal and make utilization of individual data. The following blueprints our privacy policy.
          </p>
          <ul className="my-4">
            <li className="my-3">
              Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.
            </li>
            <li className="my-3">
              We will gather and utilization of individual data singularly with the target of satisfying those reasons indicated by us and for other good purposes, unless we get the assent of the individual concerned or as required by law.
            </li>
            <li className="my-3">
              We will just hold individual data the length of essential for the satisfaction of those reasons.
            </li>
            <li className="my-3">
              We will gather individual data by legal and reasonable means and, where fitting, with the information or assent of the individual concerned.
            </li>
            <li className="my-3">
              Personal information ought to be important to the reasons for which it is to be utilized, and, to the degree essential for those reasons, ought to be exact, finished, and updated.
            </li>
            <li className="my-3">
              We will protect individual data by security shields against misfortune or burglary, and also unapproved access, divulgence, duplicating, use or alteration.
            </li>
            <li className="my-3">
              We will promptly provide customers with access to our policies and procedures for the administration of individual data.
            </li>
            <li className="my-3">
              We are focused on leading our business as per these standards with a specific end goal to guarantee that the privacy of individual data is secure and maintained.
            </li>
          </ul>
        </div>
        <div className="my-5">
          <h4>Terms of Use</h4>
          <div className="my-4">
            <h5>1. Terms</h5>
            <p>
              By accessing this website, you are agreeing to be bound by these web site Terms and Conditions of Use,
              applicable laws, and regulations and their compliance. If you disagree with any of the stated terms and
              conditions, you are prohibited from using or accessing this site. The materials contained in this site
              are secured by relevant copyright and trademark law.
            </p>
          </div>
          <div className="my-4">
            <h5>2. Use License</h5>
            <p>
              Permission is allowed to temporarily download one duplicate of the materials (data or programming) on
              StructSource's site for individual and non-business use only. This is the just a permit or license and not an
              exchange of title, and under this permit, you may not: modify or copy the materials; use the materials
              for any commercial use, or for any public presentation (business or non-business); attempt to decompile
              or rebuild any product or material contained on StructSource's site; remove any copyright or other restrictive
              documentations from the materials; or transfer the materials to someone else or even "mirror" the
              materials on other servers. This permit might consequently be terminated if you disregard any of
              these confinements and may be ended by StructSource whenever deemed. After permit termination or when your
              viewing permit is terminated, you must destroy any downloaded materials in your ownership whether in
              electronic or printed form.
            </p>
          </div>
          <div className="my-4">
            <h5>3. Disclaimer</h5>
            <p>
              By accessing this website, you are agreeing to be bound by these web site Terms and Conditions of Use,
              applicable laws, and regulations and their compliance. If you disagree with any of the stated The materials
              on StructSource's site are given "as is". StructSource makes no guarantees, communicated or suggested, and thus renounces
              and nullifies every single other warranty, including without impediment, inferred guarantees or states of
              merchantability, fitness for a specific reason, or non-encroachment of licensed property or other infringement
              of rights. Further, StructSource does not warrant or make any representations concerning the precision, likely
              results, or unwavering quality of the utilization of the materials on its Internet site or generally
              identifying with such materials or on any destinations connected to this website.
            </p>
          </div>
          <div className="my-4">
            <h5>4. Constraints</h5>
            <p>
              By accessing this website, you are agreeing to be bound by these web site Terms and Conditions of Use,
              applicable laws, and regulations and their compliance. If you disagree with any of the stated terms and
              conditions, you are prohibited from using or accessing this site. The materials contained in this site
              are secured by relevant copyright and trademark law.
            </p>
          </div>
          <div className="my-4">
            <h5>5. Amendments and Errata</h5>
            <p>
              The materials showing up on StructSource's site could incorporate typographical, or photographic mistakes.
              StructSource does not warrant that any of the materials on its site are exact, finished, or current. StructSource
              may roll out improvements to the materials contained on its site whenever without notification. StructSource
              does not, then again, make any dedication to updating the materials.
            </p>
          </div>
          <div className="my-4">
            <h5>6. Links</h5>
            <p>
              StructSource has not checked on the majority of the websites or links connected to its website and is not in
              charge of the substance of any such connected webpage. The incorporation of any connection does not
              infer support by StructSource of the site. Utilization of any such connected site is at the user's own risk.
            </p>
          </div>
          <div className="my-4">
            <h5>7. Site Terms of Use Modifications</h5>
            <p>
              StructSource may update these terms of utilization for its website whenever without notification. By utilizing
              this site you are consenting to be bound by the then current form of these Terms and Conditions of Use.
            </p>
          </div>
        </div>
      </Container>
        <Footer />
    </>
  );
};

export default PrivacyPolicy;
