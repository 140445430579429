import './App.scss';
import { Image } from "react-bootstrap";
import { wip } from 'web/assets';
import Routers from "./Routers";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { BreakpointProvider, setDefaultBreakpoints } from "react-socks";


const App = () => {
  setDefaultBreakpoints([
    { xsmall: 0 }, // all mobile devices
    { small: 576 }, // mobile devices (not sure which one's this big)
    { medium: 768 }, // ipad, ipad pro, ipad mini, etc
    { large: 992 }, // smaller laptops
    { xlarge: 1200 }, // laptops and desktops
    { xxlarge: 1400 }, // laptops and desktops
  ]);

  return (
    <div className="overflow-hidden">
      <BreakpointProvider>
        <Routers />
        <ToastContainer />
      </BreakpointProvider>
    </div>
  );
}

export default App;
