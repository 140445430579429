import { TfiEmail } from "react-icons/tfi";
import { BsTelephone } from "react-icons/bs";
import { Row, Col } from "react-bootstrap";
import Map from "web/components/Map";

const CompanyAddressContact = ({ className, textColor }) => {
  return (
    <div
      className={
        " d-flex flex-column justify-content-center align-items-center" + " " +
        className
      }
    >
      <Row className="w-100">
        <Col lg={6} className="p-0" style={{minHeight: "300px"}}>
          <Map />
        </Col>
        <Col lg={6} className="d-flex flex-column py-5 ps-4">
          <div className="">
            <div>
              <h1 className={"f-contact-heading " + textColor}>Head Office Address</h1>
              <p className={"f-contact-p " + textColor}>
                Office No. 71 & 72, A Wing 3rd floor, K.K. Market, Satara Road,
                Bibwewadi, Pune
              </p>
            </div>
            <div className="my-4">
              <h1 className={"f-contact-heading " + textColor}>Area Offices</h1>
              <h4 className={"f-contact-sub-heading mb-0 mt-2 " + textColor}>Office Number 2</h4>
              <p className={"f-contact-p " + textColor}>Samruddhi Apartment, Near PMC, Pune</p>
              <h4 className={"f-contact-sub-heading mb-0 mt-2 " + textColor}>Office Number 3</h4>
              <p className={"f-contact-p " + textColor}>
                2 nd Floor Chatur apartment, Law College load, Above Chandradeep
                Shop, Pune.
              </p>
            </div>
          </div>
          <div className="">
            <a className={"f-contact-heading mt-2 text-decoration-none cursor-pointer fw-bold" + textColor} href="mailto:structsource@gmail.com">
              {" "}
              <span>
                <TfiEmail />
              </span>{" "}
              structsource@gmail.com
            </a>
            <h1 className={"f-contact-heading mt-2" + textColor}>
              <BsTelephone /> <a href="tel:9975586807" className="text-decoration-none text-white fw-bold">9975586807</a> / <a href="tel:9922414601" className="text-decoration-none text-white fw-bold">9922414601</a> / <a href="tel:9422311507" className="text-decoration-none text-white fw-bold">9422311507</a>
            </h1>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyAddressContact;
