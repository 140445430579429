import React from "react";
import NavBar from "web/components/NavBar";
import { Row, Col, Form, Button } from "react-bootstrap";
import Footer from "web/components/Footer";
import CompanyAddressContact from "web/components/CompanyAdressContact";
import { useSpring, animated } from "react-spring";
import * as animations from "../utils/animations";
import axios from "axios";
import { useEffect, useRef } from "react";
import ContactUsForm from "web/components/ContactUsForm";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


const Contact = () => {
  const slideFromRight = useSpring(animations.slideFromRight);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  return (
    <div className="contact-page">
      <Helmet>
        <title>{`StructSource | Contact`}</title>
        <meta name="description" content="Contact" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar />
      <animated.div
        className="aboutus-contact  home-background d-flex flex-row justify-content-center align-items-center position-relative"
        style={slideFromRight}
      >
        <div
          className="contact-us w-100 m-0 d-flex flex-md-row flex-column justify-content-center "
          style={{ zIndex: 2 }}
        >
          <div className="get-in-touch-container get-in-touch-container d-flex flex-md-column flex-row justify-content-center">
            <h1 className="w-100 f-get-in-touch text-md-start text-end ">
              Get In
            </h1>
            <h1 className="w-100 f-get-in-touch text-md-end text-start ms-3 ms-md-0">
              Touch
            </h1>
          </div>
          <div className="home-contact-form px-4 px-lg-0">
            <ContactUsForm />
          </div>
        </div>
        <Row className="position-absolute bottom-0 overflow-hidden d-flex justify-content-end align-items-center">
          <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-1">
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div "></div>
            <div className="hex-bg-div opacity-0"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div opacity-0"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
          </Col>
          <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-2">
          <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
          </Col>
          <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-3">
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
            <div className="hex-bg-div"></div>
          </Col>
        </Row>
      </animated.div>
      <CompanyAddressContact
        className=" aboutus-background"
        textColor=" text-dark"
      />
      <Footer />
    </div>
  );
};

export default Contact;
