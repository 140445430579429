import { founder_avinash, founder_gourav, founder_saurabh, avinashAgrawal, saurabhBorkar } from "../assets";

const founders = [
    {
        name:"Avinash G Agrawal (M.Tech. STRUCTURE)",
        position:"Partner and Founder Member of Struct-Source Engineering",
        bio:"Avinash Agrawal is a founder member of Struct-Source Engineering Solutions. A result driven, self-motivated andinfluencing Structural Engineer having more than 11 years of experience in structural analysis and design of structures. Has designed many projects for buildings, bridges and othercommercial entities. Has excellent communication skills, is a team builder, a problem solver which are some of hisattributes. Has a blend of technical and management capabilities",
        img:{avinashAgrawal}
    },
    {
        name:"Saurabh D. Borkar (M.Tech. STRUCTURE)",
        position:"Partner and Founder Member of Struct-Source Engineering",
        bio:"Founder of the company and currently the Managing Director. More than 11 years of experience in the field of Design of Roads & Bridges, software development, planning of Industrial Structures, Multi-storied Buildings, Hotels, Road Networks, Effluent Treatment and Water Treatment Plants, etc. Project Management of Bridges, Roads & Buildings.",
        img:{saurabhBorkar}
    },
    {
        name:"Gaurav S Parmar (M.Tech. STRUCTURE)",
        position:"Partner and Founder Member of Struct-Source Engineering",
        bio:"An experienced structural engineer with over 11 years valuable experience of successfully completing projects from concept phase to final tendering and design report. Has ability to produce maximum efficiency by finding solutions to requirements of Client / Architectural in tight work schedule by applying latest innovations. Has extensive experience Structural design of Bridges, Roads and Buildings",
        img:{founder_gourav}
    }
];

export default founders;