import React, { useEffect } from "react";
import NavBar from "web/components/NavBar";
import Footer from "web/components/Footer";
import { Row, Col, Form, Button, Image, Container } from "react-bootstrap";
import { aboutus_man, aboutus_mission, career_img } from "web/assets";
import { useSpring, animated } from "react-spring";
import * as animation from "../utils/animations";
import { useRef } from "react";
import CareerForm from "web/components/CareerForm";

import {Breakpoint} from "react-socks";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


const Career = () => {
  const slideFromRight = useSpring(animation.slideFromRight);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  return (
    <div className="aboutus-page user-select-none">
      <Helmet>
        <title>{`StructSource | Careers`}</title>
        <meta name="description" content="Careers" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar />
      <animated.div style={slideFromRight}>
        <div className="h-90vh first-section d-flex flex-column justify-content-between align-items-center overflow-hidden">
          <Breakpoint large up className="w-100">
            <Image className="position-absolute w-100" src={career_img} style={{ filter: "blur(2px)" }} />
          </Breakpoint>
          <Breakpoint medium down className="d-flex justify-content-center w-100 overflow-hidden position-absolute ">
            <Image className="h-100vh" src={career_img} style={{ filter: "blur(2px)", top: 0 }} />
          </Breakpoint>
          <div className="w-100 h-100" style={{ backgroundColor: "rgba(223, 211, 190, 0.6)", zIndex: 3 }}>
            <Container className="h-100">
              <Row
                className="w-100 h-100 px-3 align-items-center"
                style={{ maxWidth: 1366 + "px", zIndex: 20 }}
              >
                <Col className="col-12 col-md-6 p-0 ps-lg-5 d-flex flex-column justify-content-center">
                  <h1 className="f-home-title">Careers</h1>
                  <p className="f-home-p">
                    Struct-Source Engineering Solutions provides opportunities to upcoming
                    engineers who are going to take the nation forward.
                    We are looking for young technical interns/people, professional
                    in their domains, eager to learn, willing to contribute in the growth
                    of themselves along with the firm and have sound and morals.
                  </p>
                </Col>
                {/* <Col className="about-us-image col-12 col-md-6 p-0 d-flex justify-content-center">
                  <img src={career_img} alt="" className="py-3" />
                </Col> */}
              </Row>
            </Container>
          </div>
        </div>
        <div className="position-relative home-background aboutus-contact d-flex flex-row justify-content-center align-items-center">
          <div
            className="contact-us w-100 m-0 d-flex flex-md-row flex-column justify-content-center "
            style={{ zIndex: 2 }}
          >
            <div className="get-in-touch-container d-flex flex-md-column flex-row justify-content-center">
              <h1 className="w-100 f-get-in-touch text-md-start text-end ">
                Apply
              </h1>
              <h1 className="w-100 f-get-in-touch text-md-end text-start ms-3 ms-md-0">
                Here
              </h1>
            </div>
            <div className="home-contact-form px-4 px-lg-0">
              <CareerForm />
            </div>
          </div>
          <Row className="position-absolute bottom-0 overflow-hidden d-flex justify-content-end align-items-center">
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-1">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div "></div>
              <div className="hex-bg-div opacity-0"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div opacity-0"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-2">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
            <Col className="d-flex flex-row justify-content-center align-items-center hex-bg-row-3">
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
              <div className="hex-bg-div"></div>
            </Col>
          </Row>
        </div>
        <Footer />
      </animated.div>
    </div>
  );
};

export default Career;
