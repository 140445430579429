import React, { useState, useEffect } from "react";
import { Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Footer from "web/components/Footer";
import NavBar from "web/components/NavBar";
import { list, project_detail_placeholder, back_arrow } from "../assets";
import { blogs } from "../data/blog";

const BlogInfo = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  const { slug } = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    let selectedEntity = blogs.filter((blog) => blog.slug === slug);
    setSelectedEntity(selectedEntity[0])
  }, [slug])

  console.log(selectedEntity)

  return (
    <>
      <NavBar />
      <div>
        <animated.div className="container  mt-5" >
          <animated.div className="d-flex flex-row justify-content-start align-items-start">
            <div
              className="home-background text-white py-3 px-3 cursor-pointer me-1"
              style={{ fontWeight: "lighter", display: "inline-block" }}
              onClick={() => navigate("/blog")}
            >
              <Image src={back_arrow} width="20" />
            </div>
            <Breadcrumb className="breadcrumb-projectinfo align-self-center">
              <Breadcrumb.Item onClick={() => navigate("/blog")}>
                Blogs
              </Breadcrumb.Item>
              <Breadcrumb.Item>{selectedEntity?.name}</Breadcrumb.Item>
            </Breadcrumb>
          </animated.div>
          <animated.div>
            <Row className="my-5">
              <Col className="col-12 col-lg-8">
                <Image
                  src={selectedEntity?.image}
                  style={{ minWidth: "850px", maxHeight: "600px" }}
                />
              </Col>
              <Col className="col-12 col-lg-4 mt-3 mt-lg-0">
                <div className="aboutus-background mb-2 p-2">
                  <p className="f-home-p">{selectedEntity?.name}</p>
                </div>
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Author</h4>
                  <p className="f-home-p">{selectedEntity?.author_name}</p>
                </div>
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Published On</h4>
                  <p className="f-home-p">{selectedEntity?.published_on}</p>
                </div>
              </Col>
            </Row>
            <div>
              <p className="f-home-p">
                {selectedEntity?.content}
              </p>
            </div>
          </animated.div>
        </animated.div>
      </div>
      <Footer />
    </>
  );
};

export default BlogInfo;
