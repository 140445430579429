import { Image } from "react-bootstrap";
import { founders_bg } from "web/assets";
import founders from "web/data/founders";

const FoundingMembers = () => {
  return (
    <>
      <div className="founding-members-desktop bg-white overflow-hidden position-relative">
        <Image className="position-absolute" style={{filter: "blur(3px)"}} src={founders_bg}/>
        <div
          className="h-120vh d-flex flex-column justify-content-around align-items-start"
          style={{ maxWidth: 1366 + "px", margin: "auto", zIndex: 20 }}
        >
          <h1 className="f-home-title text-center w-100" style={{zIndex: 20}}>Founding Members</h1>
          {founders.map((founder) => (
            <div className="founding-member-container" key={founder.name}>
              <div className="hexagon-image-founder">
                <img src={Object.values(founder.img)} alt="" className="w-100" style={{ objectFit: 'cover' }} />
              </div>
              <div className="founding-member-info-container">
                <div className="founding-member-info d-flex flex-column justify-content-center align-items-start">
                  <h4 className="founding-member-name mb-0">{founder.name}</h4>
                  <h6 className="founding-member-title mb-0">{founder.position}</h6>
                  <p className="founding-member-bio">{founder.bio}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* ****************************************************************************** */}
      <div className="founding-members-mobile bg-white pb-2">
        <div
          className="d-flex flex-column justify-content-around align-items-start"
          style={{ maxWidth: 1366 + "px", margin: "auto" }}
        >
          <h1 className="f-home-title text-center w-100">Founding Members</h1>
          {founders.map((founder) => (
            <div className="founding-member-container ms-2 me-2 p-2 m-2" key={founder.name} style={{ backgroundColor: "rgba(223, 211, 190, 0.2)" }}>
              <div className="d-flex flex-row jusify-content-start align-items-center">
                <div className="hexagon-image">
                  <img src={Object.values(founder.img)} alt="" />
                </div>
                <h4 className="founding-member-name ms-2">{founder.name.split('(')[0]}<br />{"(" + founder.name.split('(')[1]}</h4>
              </div>
              <div className="">
                <div className="founding-member-info">
                  <h6 className="founding-member-title">{founder.position}</h6>
                  <p className="founding-member-bio">{founder.bio}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FoundingMembers;
