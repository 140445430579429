import axios from "axios";

const saveCareerForm = async (e, data) => {
  e.preventDefault();
  // const endpoint = "https://script.google.com/macros/s/AKfycbz6s7sxHnljpNcPnmZ7c6nhvAak2a8goYc20QaX2ptVQmwMZTce5l6aMG6c4xVG7WA-qA/exec";
  // const response = await axios.post(endpoint, data);
  // console.log(response);
  // return response;
  const admin_subject = `Career Enquiry from website`;
  const admin_message = `Hello Team,
  <br/>
    ${data.get("name")} is trying to contact you.
    <br/>
    <br/>
    Details are as follows:
    <br/>
    Name: ${data.get("name")}
    <br/>
    Mobile: ${data.get("phone")}
    <br/>
    Email: ${data.get("email")}
    <br/>
    Profile: ${data.get("profile")}
    <br/>
    Experience: ${data.get("experience")}
    <br/>
    Message: ${data.get("message")}
  `;
  const subject = `StructSource Team`;
  const message = `Hello ${data.get(
    "name"
  )}, <br/><br/> Thank you for contacting us. <br/> We'll get back to you soon.<br/><br/>Regards,<br/>Team StructSource.`;
  const url = `https://teson.in/api/openapi/clientsmtp/6026e06f-576f-4a6f-a5b6-cd53ab861d63/contactus/?subject=${subject}&message=${message}&to=${data.get(
    "email"
  )}&admin_subject=${encodeURIComponent(admin_subject)}&admin_message=${encodeURIComponent(admin_message)}`;
  try {
    const response = await axios.post(
      url,
      data
    );
    console.log(response);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default saveCareerForm;
