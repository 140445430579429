import React from "react";
import { Button, Image } from "react-bootstrap";
import { footer_bg } from "web/assets";
import { Link } from "react-router-dom";
import {
  logo,
  logo_no_bg,
  facebook_logo,
  instagram_logo,
  twitter_logo,
  linkedin_logo,
} from "web/assets";
import { Breakpoint } from "react-socks";

const Footer = () => {
  return (
    <>
      <div className="footer d-flex flex-column justify-content-end align-items-center bg-white">
        <div
          className="footer-content d-flex flex-column justify-content-around align-items-center mb-2"
          style={{ maxWidth: 1366 + "px" }}
        >
          <div className="footer-logo-heading d-flex flex-lg-row flex-column justify-content-around align-items-center">
            <div className="footer-logo-heading-content d-lg-flex flex-row justify-content-around align-items-center">
              <img
                src={logo_no_bg}
                width="50px"
                height="50px"
                alt=""
                style={{ backgroundColor: "#C8E164" }}
              />
              <p className="mb-0 f-footer-body-1 text-uppercase ps-2">
                Structsource&nbsp;Engineering Solutions
              </p>
            </div>
            <div className="footer-social d-flex flex-row justify-content-around align-items-center">
              <span className="footer-link-border d-lg-block d-none"></span>
              <Image src={facebook_logo}></Image>
              <Image src={twitter_logo}></Image>
              <Image src={linkedin_logo}></Image>
              <a href="https://www.instagram.com/structsourceeng">
                <Image src={instagram_logo}></Image>
              </a>
            </div>
          </div>
          <Breakpoint medium up className="footer-links d-flex flex-row justify-content-around">
            <Link to="/" className="f-footer-link">
              Home
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/projects" className="f-footer-link">
              Projects
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/aboutus" className="f-footer-link">
              About Us
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/team" className="f-footer-link">
              Team
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/career" className="f-footer-link">
              Careers
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/blog" className="f-footer-link">
              Blogs
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/contactus" className="f-footer-link">
              Contact
            </Link>
            <span className="footer-link-border"></span>
            <Link to="/privacypolicy" className="f-footer-link">
              Privacy policy
            </Link>
          </Breakpoint>
          <Breakpoint small down className="footer-links">
            <Button variant="link" size="sm" as={Link} to="/" className="m-2 f-footer-link text-decoration-none text-primary">
              Home
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/projects" className="m-2 f-footer-link text-decoration-none text-primary">
              Projects
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/aboutus" className="m-2 f-footer-link text-decoration-none text-primary">
              About Us
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/team" className="m-2 f-footer-link text-decoration-none text-primary">
              Team
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/career" className="m-2 f-footer-link text-decoration-none text-primary">
              Careers
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/blog" className="m-2 f-footer-link text-decoration-none text-primary">
              Blogs
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/contactus" className="m-2 f-footer-link text-decoration-none text-primary">
              Contact
            </Button>
            <span>|</span>
            <Button variant="link" size="sm" as={Link} to="/privacypolicy" className="m-2 f-footer-link text-decoration-none text-primary">
              Privacy policy
            </Button>
          </Breakpoint>
          <div>
            <p className="f-footer-body-1 text-center text-primary" style={{ fontSize: 24 }}>
              {" "}
              "Professionalism is environmental. Amateurism is anti-environmental."
            </p>
          </div>
          <div>
            <p className="f-footer-body-2 text-center">
              © 2022 Structsource Engineering Solutions |GST - 27ACWFS3087K1Z6
              |PAN - ACWFS3087K
            </p>
          </div>
        </div>
        <div className="w-100" style={{ maxHeight: 70 + "vh" }}>
          <Image className="w-100 object-fit-fill" src={footer_bg} alt="" />
        </div>
      </div>
    </>
  );
};

export default Footer;
