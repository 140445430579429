import slugify from "slugify";
import {
  infraOne,
  infraTwo,
  infraThree,
  infraFour,
  project_detail_placeholder,
  infraFive,
  infraSeven,
  infraEight,
  infraNine,
  infraTen,
} from "../assets";


const blogs = [
  // {
  //   id: 1,
  //   name: "Marcus Rashford benched due to ‘internal disciplinary’ reasons for Manchester United vs Wolves",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraOne,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 2,
  //   name: "Design and Proof checking of 100 m long bridge work on Creek in DP road from Agasan to Kalyan Rd",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraTwo,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 3,
  //   name: "Proof checking of structural design Of Bridge on Kham river",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraThree,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 4,
  //   name: "Structural Design for MSETCL EHVT Line re-routing 260 M long Bridge housing provision of EHVT line Duct and 800 Dia Water pipe line",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraFour,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 5,
  //   name: "Design and Construction of Bridge at Hiranandani Fortune City Panvel.",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraFive,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 6,
  //   name: "Value Engineering Services for Piped Water Supply scheme for Sector-1 at Khandala",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: project_detail_placeholder,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 7,
  //   name: "Professional Service for Land pattern study, preliminary survey, Boundary marking, utility survey design, and Budget Preparation for Khandala Site",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraSeven,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
  // {
  //   id: 8,
  //   name: "Consultancy for Design of bridge on Nala in prabhag no 20 and 26 at Mohammadwadi, Pune",
  //   author_name: "Gaurav Parmar",
  //   published_on: "31st December 2022",
  //   image: infraEight,
  //   content: "Marcus Rashford was not included in Man Utd starting XI against Wolves because of “internal disciplinary” reasons, manager Erik ten Hag revealed before the game on Saturday. Ten Hag also said that Luke Shaw will continue in his role as a centre-back ahead of Maguire and Lindelof. After Ten Hag’s statement, former United midfielder Paul Scholes spoke to BT Sport and said that he ‘loves’ Ten Hag’s hardline approach. 'Ten Hag doesn’t care who it is. Rashford would be the first name on the team-sheet,' said Scholes.",
  // },
];

blogs.map((blog) => {
  blog.slug = slugify(blog.name);
  blog.type = "Blogs"
  return blog
})

export { blogs };
