import React, { useEffect } from "react";
import NavBar from "web/components/NavBar";
import Footer from "web/components/Footer";
import { useState } from "react";
import { useSpring, animated } from "react-spring";
import * as animations from "../utils/animations";
import { team_bg } from "../assets";
import { blogs } from "../data/blog";
import { Image, Row, Card } from "react-bootstrap";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";


const Blog = () => {
    const { slug } = useParams()
    const location = useLocation();
    const fade = useSpring(animations.fadeOut);
    const navigate = useNavigate()
    const slideFromRight = useSpring(animations.slideFromRight);

    useEffect(() => {
        window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
    }, []);

    if (slug) {
        return (
            <Outlet />
        )
    }

    return (
        <>
            <Helmet>
                <title>{`StructSource | Blog`}</title>
                <meta name="description" content="Blog" />
                <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
                <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
            </Helmet>
            <NavBar />
            <Image className="position-absolute w-100" style={{ filter: "blur(4px)" }} src={team_bg} />
            <animated.div
                className="mt-5 pt-5 d-flex flex-column justify-content-center align-items-center"
                style={slideFromRight}
            >
                <h1 className="p-0 f-project-title" style={{ zIndex: 20 }}>Blogs</h1>
            </animated.div>
            <animated.div
                className="first-section d-flex flex-column justify-content-center"
                style={slideFromRight}
            >

                <Row className="row-cols-3 d-flex justify-content-center">
                    {
                        blogs?.length > 1 && blogs.map((blog) => {
                            return (
                                <Card
                                    className="m-4 blog-card d-flex justify-content-start align-items-center cursor-pointer"
                                    style={{ width: 500, height: 300 }}
                                    onClick={() => navigate(`/blog/${blog.slug}/`)}
                                >
                                    <Card.Img className="mt-2" variant="top" src={blog.image} style={{ width: 480, height: 200 }} />
                                    <Card.Title className="my-2 f-home-p">{blog.name}</Card.Title>
                                </Card>
                            )
                        })
                    }
                    {
                        blogs?.length == 0 && (<div className="d-flex justify-content-center w-100 h2" style={{zIndex: 20}}>
                            No blogs posted yet!
                        </div>)
                    }
                </Row>
            </animated.div>
            <Footer />
        </>
    );
};

export default Blog;
