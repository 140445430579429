import React, { useState, useRef, useEffect } from "react";
import NavBar from "web/components/NavBar";
import { Row, Col, Image } from "react-bootstrap";
import Footer from "web/components/Footer";
import { useSpring, animated } from "react-spring";
import { x, project_detail_placeholder, list } from "../assets";
import { buildings, infrastructures } from "../data/work";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";


const entities = {
  in: "infrastructures",
  b: "buildings",
  cross: "x",
  list: "list",
};

const ProjectsList = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);
  const building_info_ref = useRef(null);
  const building_list_ref = useRef(null);
  const infrastructure_info_ref = useRef(null);
  const infrastructure_list_ref = useRef(null);
  const location = useLocation();

  const [selectedEntityBuilding, setSelectedEntityBuilding] =
    useState(undefined);
  const [selectedEntityInfra, setSelectedEntityInfra] = useState(undefined);

  const onClickHandler = (clickedEntity) => {
    if (clickedEntity === entities.list) {
      setSelectedEntityBuilding(undefined);
      setSelectedEntityInfra(undefined);
    }
  };

  useEffect(() => {
    if (selectedEntityInfra)
      infrastructure_info_ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });

    if (selectedEntityBuilding)
      building_info_ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
  }, [selectedEntityInfra, selectedEntityBuilding]);
  const infraClickHandler = (infra) => {
    setSelectedEntityInfra(infra);
  };

  const buildingClickHandler = (building) => {
    setSelectedEntityBuilding(building);
  };

  const slideFromRight = useSpring({
    from: { x: -2000 },
    to: { x: 0 },
    reset: true,
    delay: 900,
  });

  return (
    <>
    <Helmet>
        <title>{`StructSource | Projects`}</title>
        <meta name="description" content="Projects" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <NavBar />
      <animated.div
        style={slideFromRight}
        className="first-section d-flex flex-column justify-content-space-evenly align-items-center"
      >
        <h1 className="f-project-title text-center">
          List of Major List Completed and in Hand
        </h1>
        <div className="w-100">
          <Row>
            <Col className="p-0 d-flex flex-row justify-content-space-evenly align-items-center">
              <div className="position-relative hex-rotate-animate-container">
                <div
                  className="hex-rotate-animate cursor-pointer"
                  onClick={() => {
                    building_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-animate-h1">Total Buildings</p>
                <p
                  className="bottom-0 click-to-view cursor-pointer"
                  onClick={() => {
                    building_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  Click to view projects
                </p>
                <p
                  className="f-h1 position-absolute f-h1-highlight cursor-pointer"
                  onClick={() => {
                    building_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  +{buildings.length}
                </p>
              </div>
              <div className="position-relative hex-rotate-animate-container">
                <div
                  className="hex-rotate-animate cursor-pointer"
                  onClick={() => {
                    infrastructure_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-animate-h1">Infrastructure</p>
                <p
                  className="bottom-0 click-to-view cursor-pointer"
                  onClick={() => {
                    infrastructure_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  Click to view projects
                </p>
                <p
                  className="f-h1 position-absolute f-h1-highlight cursor-pointer"
                  onClick={() => {
                    building_list_ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }}
                >
                  +{infrastructures.length}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </animated.div>
      <animated.div className="container mt-5" ref={building_list_ref}>
        <div className="d-flex flex-row justify-content-start align-items-center">
          {/* <div
            className="home-background text-white py-3 px-3 cursor-pointer me-1"
            onClick={() => onClickHandler(entities.cross)}
            style={{ fontWeight: "lighter", display: "inline-block" }}
          >
            <Image src={x} />
          </div> */}

          <h1 className="mb-0">Buildings</h1>
        </div>
        <div>
          <ol>
            {buildings.map((building, index) => {
              return (
                <li
                  key={building.name + index}
                  onClick={() => buildingClickHandler(building)}
                  className="my-2 cursor-pointer project-list-item f-project-list-unselected"
                >
                  <Link to={building.slug}>{building.name}</Link>
                </li>
              );
            })}
          </ol>
        </div>
      </animated.div>

      <animated.div className="container mt-5" ref={infrastructure_list_ref}>
        <div className="d-flex flex-row justify-content-start align-items-center">
          {/* <div
            className="home-background text-white py-3 px-3 cursor-pointer me-1"
            onClick={() => onClickHandler(entities.cross)}
            style={{ fontWeight: "lighter", display: "inline-block" }}
          >
            <Image src={x} />
          </div>{" "} */}
          <h1 className="mb-0">Infrastructures</h1>
        </div>
        <div>
          <ol>
            {infrastructures.map((infra, index) => {
              return (
                <li
                  key={infra.name + index}
                  onClick={() => infraClickHandler(infra)}
                  className="my-2 cursor-pointer project-list-item f-project-list-unselected"
                >
                  <Link to={infra.slug}>{infra.name}</Link>
                </li>
              );
            })}
          </ol>
        </div>
      </animated.div>

      <Footer />
    </>
  );
};

export default ProjectsList;
