import React from "react";
import { useEffect } from "react";
import { Row, Col, Image, Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import Footer from "web/components/Footer";
import NavBar from "web/components/NavBar";
import { list, project_detail_placeholder, back_arrow } from "../assets";
import { buildings, infrastructures } from "../data/work";

const ProjectInfo = () => {
  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  let selectedEntity = buildings.filter(
    (building) => building.slug === location.pathname.split("/projects/")[1]
  );
  if (selectedEntity.length < 1) {
    selectedEntity = infrastructures.filter(
      (infra) => infra.slug === location.pathname.split("/projects/")[1]
    );
  }
  if (selectedEntity.length === 1) selectedEntity = selectedEntity[0];

  return (
    <>
      <NavBar />
      <div>
        <animated.div className="container  mt-5" >
          <animated.div className="d-flex flex-row justify-content-start align-items-start">
            <div
              className="home-background text-white py-3 px-3 cursor-pointer me-1"
              style={{ fontWeight: "lighter", display: "inline-block" }}
              onClick={() => navigate("/projects")}
            >
              <Image src={back_arrow} width="20" />
            </div>
            <Breadcrumb className="breadcrumb-projectinfo align-self-center">
              <Breadcrumb.Item onClick={() => navigate("/projects")}>
                {selectedEntity.type}
              </Breadcrumb.Item>
              <Breadcrumb.Item>{selectedEntity.client}</Breadcrumb.Item>
            </Breadcrumb>
          </animated.div>
          <animated.div>
            <Row className=" mt-4">
              <Col className="col-12 col-lg-8">
                <Image
                  fluid
                  src={selectedEntity.image}
                  style={{minWidth: "850px", maxHeight: "600px"}}
                />
              </Col>
              <Col className="col-12 col-lg-4 mt-3 mt-lg-0">
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Name of the work</h4>
                  <p className="f-home-p">{selectedEntity.name}</p>
                </div>
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Client</h4>
                  <p className="f-home-p">{selectedEntity.client}</p>
                </div>
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Project Cost</h4>
                  <p className="f-home-p">{selectedEntity.cost}</p>
                </div>
                <div className="aboutus-background mb-2 p-2">
                  <h4 className="f-project-info-title ">Project Status</h4>
                  <p className="f-home-p">{selectedEntity.status}</p>
                </div>
              </Col>
            </Row>
          </animated.div>
        </animated.div>
      </div>
      <Footer />
    </>
  );
};

export default ProjectInfo;
