import React, { useEffect, useRef, useState } from "react";
import NavBar from "web/components/NavBar";
import { Button, Col, Image, Row, Form, Container } from "react-bootstrap";
import {
  right_arrow,
  home_subtitle_image,
  home_title_image,
  home_aboutus,
  blue_checks_bg,
  or_arrow,
  afcons,
  arconstruction,
  hiranandani,
  katibadhya,
  nhai,
  ns,
  pmc,
  pwd,
  ramboll,
  vka,
  project_detail_placeholder,
  buildingOne,
  buildingSeven,
  buildingEight,
  infraOne,
  infraTwo,
  infraThree,
  infraFour,
  infraFive,
  infraSeven,
  infraEight,
  infraNine,
  infraTen,
  infraEleven,
  infraTwelve,
  infraThirteen,
  infraFourteen,
  infraFifteen,
  infraSeventeen,
  infraEighteen,
  infraNineteen,
  infraTwenty,
  infraTwentyOne,
  infraTwentyFive,
  infraTwentySix,
  infraTwentySeven,
  infraThirtyTwo,
  infraThirtyThree,
  infraThirtyFive,
  infraThirtySix,
  infraThirtySeven,
  infraThirtyEight,
} from "../assets";
import Footer from "web/components/Footer";
import CompanyAddressContact from "web/components/CompanyAdressContact";
import FoundingMembers from "web/components/FoundingMembers";
import { useSpring, animated, useTransition, config } from "react-spring";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as animation from "../utils/animations";
import ContactUsForm from "web/components/ContactUsForm";
import TopProjects from "web/components/TopProjects";
import { Breakpoint } from "react-socks";
import { buildings, infrastructures } from "../data/work";
import { Helmet } from "react-helmet";

const Home = () => {
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const formRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ behavior: "smooth", top: 0, left: 0 });
  }, []);

  const slideToRight = useSpring(animation.slideToRight);

  const fadeIn = useSpring(animation.fadeIn);

  let slideToLeft = useSpring(animation.slideToLeft);

  const [project, setProject] = useState(0);

  const data = [...buildings, ...infrastructures]
  const projects = data.filter((d) => d.image !== project_detail_placeholder)

  const projectTransition = useTransition(project, {
    from: {
      opacity: 0
    },
    enter: {
      opacity: 1
    },
    leave: {
      opacity: 0
    },
    config: { ...config.molasses },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setProject((old) => (old + 1) % projects.length);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`StructSource | Home`}</title>
        <meta name="description" content="We strive for excellence and provide client with best Engineering Solution within the cost frame" />
        <link rel="canonical" href={`https://structsource.co.in${location.pathname}`} />
        <link rel="alternate" hreflang="x-default" href={`https://structsource.co.in${location.pathname}`} />
      </Helmet>
      <div className="home-page user-select-none">
        <NavBar />
        <Container className="p-0 h-100vh" fluid>
          <div className="d-flex justify-content-center align-items-center">
            {projectTransition((props, item) => {
              return (
                <>
                  <Link to={`/projects/${projects[item].slug}`}>
                    <animated.div className="ms-3 position-absolute text-white image-title d-flex flex-column justify-content-center align-items-center p-2 px-3" style={{ backgroundColor: "rgba(31, 25, 20, 0.8)", zIndex: 999, bottom: 30, right: 30, borderRadius: "10px", ...props }}>{projects[item].short_name}</animated.div>
                  </Link>
                  <animated.img alt="Projects" className="position-absolute mx-4 mx-lg-auto w-100" src={projects[item].image} style={{ height: "100%", top: 0, bottom: 0, ...props }}></animated.img>
                </>
              );
            })}
          </div>
          <animated.div
            className="white-background first-section d-flex justify-content-start align-items-xl-center"
            style={fadeIn}
          >
            <div className="text-white h-100" style={{ maxWidth: 1366 + "px", zIndex: 99 }}>
              <Row className="h-100 ms-xl-5 me-xl-2 mx-2 justify-content-around home-container d-flex flex-column flex-xl-row justify-content-start justify-content-xl-start align-items-center">
                <Col
                  xs={12}
                  lg={12}
                  xl={6}
                  className="d-flex flex-column justify-content-center"
                >
                  <Breakpoint xlarge up>
                    <h1 className="f-home-title text-center text-xl-start" style={{ color: "#524234" }}>
                      We strive for excellence and provide client with best
                      Engineering&nbsp;Solution{" "}
                      <br className="d-none d-lg-block" />
                      within the cost frame.
                    </h1>
                    <Row className="flex-lg-row pt-3">
                      <Col xs={6}>
                        <button
                          className="home-contact-us-button f-home-contact-button"
                          onClick={() => formRef.current.scrollIntoView()}
                        >
                          Contact Us
                        </button>
                      </Col>
                      <Col xs={6} className="d-flex justify-content-start align-items-center ">
                        <button
                          className="d-flex align-items-center home-view-projects-button f-home-view-projects-button"
                          onMouseEnter={() => setIsBeingHovered(true)}
                          onMouseLeave={() => setIsBeingHovered(false)}
                          style={{ color: "#524234" }}
                          onClick={() => navigate("/projects")}
                        >
                          View Projects
                          <animated.img
                            className="ms-2"
                            src={right_arrow}
                            alt=""
                            style={isBeingHovered ? { color: "#524234", ...slideToRight } : { color: "#524234", ...slideToLeft }}
                          />
                        </button>
                      </Col>
                    </Row>
                  </Breakpoint>
                  <Breakpoint large down>
                    <h1 className="mt-5 f-home-title text-center" style={{ color: "#524234" }}>
                      We strive for excellence and provide client with best
                      Engineering Solution within the cost frame.
                    </h1>
                    <div className="mt-2 mt-md-3 d-flex flex-column justify-content-center align-items-center">
                      <button
                        className="home-contact-us-button f-home-contact-button"
                        onClick={() => formRef.current.scrollIntoView()}
                      >
                        Contact Us
                      </button>
                      <button
                        className="mt-3 d-flex align-items-center home-view-projects-button f-home-view-projects-button"
                        onMouseEnter={() => setIsBeingHovered(true)}
                        onMouseLeave={() => setIsBeingHovered(false)}
                        style={{ color: "#524234" }}
                        onClick={() => navigate("/projects")}
                      >
                        View Projects
                        <animated.img
                          className="ms-2"
                          src={right_arrow}
                          alt=""
                          style={isBeingHovered ? { color: "#524234", ...slideToRight } : { color: "#524234", ...slideToLeft }}
                        />
                      </button>
                    </div>
                  </Breakpoint>
                </Col>
                {/* <Col xs={12} lg={6} className="home-hexagon-images mt-4 mt-lg-0 ">
                  <div className="d-flex justify-content-end w-100 d-flex justify-content-center align-items-center">
                    <img
                      src={home_title_image}
                      alt=""
                      className="home-large-hexagon position-relative"
                    />
                    <img
                      src={home_subtitle_image}
                      alt=""
                      className="position-absolute home-small-hex"
                    />
                  </div>
                </Col> */}
              </Row>
            </div>
          </animated.div>
        </Container>
        <TopProjects />
        <div className="clients-we-have-worked-with w-100 d-flex flex-column justify-content-around">
          <h1 className="f-home-title text-center">
            Clients we have worked with
          </h1>
          <div className="clients">
            <Row>
              <Col
                className="d-flex justify-content-center align-items-center p-0 w-100"
                style={{ marginBottom: -3 + "vw" }}
              >
                <div className="hexagon-image-client">
                  <img src={afcons} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={arconstruction} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={hiranandani} alt="" className="" />
                </div>
              </Col>
            </Row>
            <Row className="flex-column justify-content-center">
              <Col className="d-flex justify-content-center align-items-end p-0">
                <div className="hexagon-image-client">
                  <img src={katibadhya} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={nhai} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={ns} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={pmc} alt="" className="" />
                </div>
              </Col>
            </Row>
            <Row>
              <Col
                className="d-flex justify-content-center align-items-start p-0 w-100"
                style={{ marginTop: -3 + "vw" }}
              >
                <div className="hexagon-image-client">
                  <img src={pwd} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={ramboll} alt="" className="" />
                </div>
                <div className="hexagon-image-client">
                  <img src={vka} alt="" className="" />
                </div>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col className="p-0 d-flex flex-row justify-content-space-evenly align-items-center">
                <div className="position-relative hex-rotate-animate-container">
                  <div className="hex-rotate-animate">
                    <div className="top"></div>
                    <div className="middle"></div>
                    <div className="bottom"></div>
                  </div>
                  <p className="hex-rotate-animate-h1">Total Projects</p>
                  <p className="f-h1 position-absolute f-h1-highlight">102</p>
                </div>
                <div className="position-relative hex-rotate-animate-container">
                  <div className="hex-rotate-animate">
                    <div className="top"></div>
                    <div className="middle"></div>
                    <div className="bottom"></div>
                  </div>
                  <p className="hex-rotate-animate-h1">Amount</p>
                  <p className="f-h1 position-absolute f-h1-highlight">
                    +452 CR
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="position-relative aboutus-section">
          <div className="d-flex justify-content-center">
            <Container
              className="position-relative mx-3 mv-5 mt-0 py-5"
              style={{ zIndex: 2 }}
            >
              <h1 className="f-home-title text-center">About Us</h1>
              <p className="f-home-p">
                Struct-Source Engineering Solutions is an engineering firm
                established by a group of expert Engineers with wide experience.
                We have experienced professionals with stellar profession and
                having years of experience in design consultancy and management of
                various buildings as well as infrastructure projects. Our team
                consists of engineering masters of various fields who have taken
                rich experience in multi-nationals and now are a part of
                Struct-Source. We strive for excellence and provide client with
                best Engineering Solution within the cost frame. We have group of
                Structural Engineers, Landscape Engineers, Geo-tech Engineers and
                Project managers. We provide all services right from planning,
                designing, estimation to final project management.
              </p>
            </Container>
          </div>
          <div className="position-absolute about-text">
            <h1 className="f-background-about">About</h1>
          </div>
          <Image className="w-100" src={home_aboutus} />
        </div>
        <div className="area-of-specialization px-5 py-5 h-90vh d-flex flex-column align-items-center justify-content-center">
          <h1 className="f-home-title mb-5">Area of Specialization</h1>
          <Row
            className="hex-rotate-rows d-flex flex-row flex-wrap justify-content-center align-items-center w-100"
            style={{ maxWidth: 1366 + "px", margin: "auto" }}
          >
            <Col
              xs={6}
              className="d-flex p-0 flex-column justify-content-start align-items-end hex-rotate-col-1"
            >
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">
                  Value <br /> Engineering
                </p>
              </div>
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">
                  Structural <br /> Auditing
                </p>
              </div>
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">
                  Enabling Works
                  <span className="hex-rotate-p">
                    <br />
                    (Supporting Frame Structures, Scaffolding Design, Sheet
                    Piles, Cofferdams)
                  </span>
                </p>
              </div>
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">Architectural Designs</p>
              </div>
            </Col>
            <Col
              xs={6}
              className="d-flex flex-column justify-content-start align-items-start"
            >
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">
                  Structural Design
                  <span className="hex-rotate-p">
                    <br />
                    (RCC, structural steel, and Pre-stress)
                  </span>
                </p>
              </div>
              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">BIM Services</p>
              </div>

              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">
                  Infrastructure Design
                  <span className="hex-rotate-p">
                    <br />
                    (Roads, ROB, River Bridges, Culverts)
                  </span>
                </p>
              </div>

              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">Civil Execution Works</p>
              </div>

              <div className="position-relative">
                <div className="hex-rotate">
                  <div className="top"></div>
                  <div className="middle"></div>
                  <div className="bottom"></div>
                </div>
                <p className="hex-rotate-h1">Interior Designs</p>
              </div>
            </Col>
          </Row>
        </div>
        <FoundingMembers />
        <div
          className="d-flex flex-column justify-content-around pb-3"
          style={{
            background: `top / cover no-repeat url(${blue_checks_bg})`,
          }}
        >
          <CompanyAddressContact
            className="contact-box-blue pt-lg-0"
            textColor=" text-white"
          />
          <div className="or-image-container position-relative w-100">
            <img src={or_arrow} alt="" className="position-absolute" />
          </div>
          <div
            className="w-100 pt-8 m-0 d-flex flex-md-row flex-column justify-content-center align-items-center position-relative"
            style={{ zIndex: 2, minHeight: 830 + "px" }}
            ref={formRef}
          >
            <div className="get-in-touch-container d-flex flex-md-column flex-row justify-content-center px-2 px-lg-0">
              <h1 className="w-100 f-get-in-touch in-home text-md-start text-end">
                Get In
              </h1>
              <h1 className="w-100 f-get-in-touch in-home text-md-end text-start ms-3 ms-md-0">Touch</h1>
            </div>
            <div className="home-contact-form px-4 px-lg-0">
              <ContactUsForm
                labelFcClassName="text-white"
                inputBgClassName="blue-background"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
